<template>
  <div>
    <a-page-header style="padding: 30px 0 10px 0" sub-title="">
      <div slot="title">
        <h1 class="f18">
          <a-icon class="gradient" type="folder-open" />
          {{ category.name }} (ID {{ category.id }})
        </h1>
      </div>
      <div slot="extra">
        <a-button type="primary" ghost @click="openCreateCategory = true">
          <a-icon type="plus-circle" /> NOVO ITEM
        </a-button>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mt-0 mb-10"
      expandIconPosition="right"
    >
      <a-collapse-panel>
        <template #header> <a-icon type="filter" /> FILTRAR </template>
        <a-row :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Nome </label>
              <a-input
                placeholder="Escreva aqui..."
                v-model="filters.searchTerm"
                @change="getCategories()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="listCategories"
      :loading="loadingCategories"
      :pagination="false"
      @change="categoryEntryTableChange"
      :scroll="{ x: 900 }"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar v-else style="color: #f56a00; background-color: #fde3cf">
              {{ record.user.first_name }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="editCategoryEntry(record)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="pagination.page"
        :total="pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      />
    </div>

    <a-drawer
      title="ADICIONAR NOVO ITEM"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateCategory"
      @close="openCreateCategory = false"
    >
      <CreateEntryDrawer
        v-if="openCreateCategory"
        :category="category"
        @onCreateCategoryEntry="onCreateCategoryEntry"
        type="create"
      />
    </a-drawer>

    <a-drawer
      title="EDITAR ITEM"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openEditCategory"
      @close="openEditCategory = false"
    >
      <CreateEntryDrawer
        v-if="openEditCategory"
        :category="category"
        :entry="theCategoryEntry"
        type="edit"
        @onEditCategoryEntry="onEditCategoryEntry"
      />
    </a-drawer>
  </div>
</template>

<script>
import roles from "@/json/roles.json";
import airportsToCreate from "@/mixins/internal-apis/airportsToCreate.json";

// components
import CreateEntryDrawer from "@/components/categories/drawers/CreateEntryDrawer.vue";

export default {
  name: "ListCategoryEntries",
  props: { idCategory: Number },
  components: {
    CreateEntryDrawer,
  },
  data() {
    return {
      airportsToCreate,
      form: this.$form.createForm(this),
      theCategoryEntry: {},
      categoryEntry: {
        filters: {
          order: "desc",
          orderBy: "created",
        },
      },
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          sorter: true,
          width: 80,
        },
        {
          title: "Nome",
          dataIndex: "name",
          key: "name",
          sorter: true,
          width: 260,
        },

        {
          title: "Ordem ",
          dataIndex: "item_order",
          sorter: true,
          key: "item_order",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Data/Hora",
          dataIndex: "created",
          key: "created",
          sorter: true,
          width: 140,
        },
        {
          title: "",
          key: "user",
          align: "right",
          scopedSlots: {
            customRender: "user",
          },
        },
        {
          title: "",
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      category: {},
      roles,
      openCreateCategory: false,
      openEditCategory: false,
      loadingCreateCategory: false,
      listCategories: [],
      allowSendEmail: [
        { label: "Sim", value: "Sim" },
        { label: "Não", value: "Não" },
      ],
      status: [
        { label: "Ativo", value: "Ativo" },
        { label: "Desativado", value: "Desativado" },
      ],
      filters: {
        users: {
          list: [],
          selected: [],
        },
        period: {
          selected: [],
        },
        searchTerm: "",
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
    };
  },
  beforeMount() {
    this.getCategories();
    this.getCategory();
  },
  mounted() {},
  methods: {
    onCreateCategoryEntry() {
      this.openCreateCategory = false;
      this.getCategories();
    },
    onEditCategoryEntry() {
      this.openEditCategory = false;
      this.getCategories();
    },
    categoryEntryTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.categoryEntry.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.categoryEntry.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getCategories();
    },
    submitUpdateCategory(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.theCategoryEntry.id;
        values.user_id = this.theCategoryEntry.user.id;
        values.category_id = this.category.id;

        if (!err) {
          this.loadingCreateCategory = true;
          this.$http
            .post("/category-entry/update", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateCategory = false;
              this.openEditCategory = false;
              this.getCategories();
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateCategory = false;
              this.openEditCategory = false;
            });
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.category_id = this.category.id;

        if (!err) {
          this.loadingCreateCategory = true;

          this.$http
            .post("/category-entry/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateCategory = false;
              this.openCreateCategory = false;
              this.getCategories();
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateCategory = false;
              this.openCreateCategory = false;
            });
        }
      });
    },
    listCategorys() {
      this.openCreateCategory = false;
      this.getCategories();
    },
    editCategoryEntry(entry) {
      this.openEditCategory = true;
      this.theCategoryEntry = entry;
    },
    changePage(current) {
      this.pagination.page = current;
      this.getCategories();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;

      this.getCategories();
    },
    getCategory() {
      this.$http
        .post(`/category/details?id=${this.idCategory}`)
        .then(({ data }) => {
          this.category = data;
          document.title = `${data.id} - ${data.name} - HAYA`;
        })
        .catch(({ response }) => {
          response;
        });
    },
    getCategories(samePage) {
      this.loadingCategories = true;

      let filters = "";

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      filters += `&order=${this.categoryEntry.filters.order}&order-by=${this.categoryEntry.filters.orderBy}`;

      this.$http
        .get(
          `/category-entry/list?page=${this.pagination.page}&category_id=${this.idCategory}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.pagination.page = 1;
          }
          this.listCategories = data.data;
          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;
          this.loadingCategories = false;
        })
        .catch(({ response }) => {
          response;
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.listCategories = [];
          this.loadingCategories = false;
        });
    },
  },
};
</script>
